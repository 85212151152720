// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".style__box--lMmc7 {\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n  gap: 1rem;\n  z-index: 1;\n}\n.style__box--lMmc7 > div {\n  width: 100%;\n}\n.style__gridContainer--MLarP {\n  overflow: hidden;\n  margin-bottom: 1rem;\n}\n.style__hide--wqqJr {\n  display: none;\n}\n.style__userGridContainer--sk7aj {\n  overflow: auto;\n  margin-bottom: 1rem;\n}\n.style__overview--VnLXr {\n  display: flex;\n  flex-direction: column;\n  height: 100%;\n  width: 100%;\n  background: #fff;\n}\n.style__overviewWithoutHeader--N_h9r {\n  display: flex;\n  flex-direction: column;\n  height: 100%;\n  width: 100%;\n}\n", "",{"version":3,"sources":["webpack://./../components/src/DrivesOverview/style.less"],"names":[],"mappings":"AAIA;EAEI,aAAA;EACA,mBAAA;EACA,8BAAA;EACA,SAAA;EACA,UAAA;AAJJ;AAFA;EASM,WAAA;AAJN;AALA;EAcI,gBAAA;EACA,mBAAA;AANJ;AATA;EAmBI,aAAA;AAPJ;AAZA;EAuBI,cAAA;EACA,mBAAA;AARJ;AAhBA;EA4BI,aAAA;EACA,sBAAA;EACA,YAAA;EACA,WAAA;EACA,gBAAA;AATJ;AAvBA;EAoCI,aAAA;EACA,sBAAA;EACA,YAAA;EACA,WAAA;AAVJ","sourcesContent":["@import '@yaak/components/src/colors';\n@import '@yaak/components/src/spacing';\n@import '@yaak/components/src/shadows';\n\n:local {\n  .box {\n    display: flex;\n    align-items: center;\n    justify-content: space-between;\n    gap: 1rem;\n    z-index: 1;\n\n    > div {\n      width: 100%;\n    }\n  }\n\n  .gridContainer {\n    overflow: hidden;\n    margin-bottom: @spacing-100;\n  }\n\n  .hide {\n    display: none;\n  }\n\n  .userGridContainer {\n    overflow: auto;\n    margin-bottom: @spacing-100;\n  }\n\n  .overview {\n    display: flex;\n    flex-direction: column;\n    height: 100%;\n    width: 100%;\n    background: @color-white;\n  }\n\n  .overviewWithoutHeader {\n    display: flex;\n    flex-direction: column;\n    height: 100%;\n    width: 100%;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"box": "style__box--lMmc7",
	"gridContainer": "style__gridContainer--MLarP",
	"hide": "style__hide--wqqJr",
	"userGridContainer": "style__userGridContainer--sk7aj",
	"overview": "style__overview--VnLXr",
	"overviewWithoutHeader": "style__overviewWithoutHeader--N_h9r"
};
export default ___CSS_LOADER_EXPORT___;
